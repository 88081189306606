import { useState, useCallback, useMemo, useEffect, useContext } from "react";
import { Button, Modal, Form, Col, Row, Container } from "react-bootstrap";
import moment from "moment";
import APIServicenew from "../utils/APIGeneralService";
import { formateDate, getCookies, setCookies } from "../utils/Helper";
import DynamicDataTableWithCount from "../components/dataTableWithCount";
import { useToasts } from "react-toast-notifications";
import { UserContext } from "../context/UserContextProvider";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";

const CallOrders = () => {
  const navigate = useNavigate();
  const token = getCookies("accessTokenleads");
  const headers = { Authorization: `Bearer ${token}` };
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [callNumber, setCallNumber] = useState("");
  const [userName, setUserName] = useState("");
  const [userMobNum, setUserMobNum] = useState("");
  const [astroName, setAstroName] = useState("");
  const [astroMobNum, setAstroMobNum] = useState("");
  const [tableData, setTableData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [userForNote, setUserForNote] = useState([]);
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  const [note, setNote] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [allDataCount, setAllDataCount] = useState();
  const [flag, setFlag] = useState(false);
  const APIServices = new APIServicenew(process.env.REACT_APP_REST_API_URL);
  const APIServicesSec = new APIServicenew(process.env.REACT_APP_ASTRO_API_URL);
  const { refreshToken } = useContext(UserContext);
  const [countData,setCountData]=useState()
  const { addToast } = useToasts();
  const { user: adminUser } = useContext(UserContext);

  // for user calling modal
  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
  const [ singleData,setSingleData ] = useState("")
  const [ showSingleDataDetail,setShowSingleDataDetail ] = useState(false)
  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };
  const APIServiceschatbot = new APIServicenew(
    process.env.REACT_APP_OTHER_API_URL
  );

  // for astrologer calling modal

  const [dial1, setDail1] = useState("Call");
  const [showCallCm1, setShowCallCm1] = useState(false);
  const [astroId, setAstroId] = useState("");

  const handleCloseCallCm1 = () => {
    setShowCallCm1(false);
  };

  const userCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "call",
      },
      headers
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };

  const handleConfirm = () => {
    userCall();
  };

  const astrologerCall = async () => {
    const res = await APIServiceschatbot.post(
      "responders/callAstrologer",
      {
        astrologerId: astroId,
        source: "call",
      },
      headers
    );
    if (res && res.status === 200) {
      setDail1(res?.data?.message);
      setShowCallCm1(false);
      addToast("Astrologer Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail1("Call");
      }, 10000);
    }
  };

  const handleConfirm1 = () => {
    astrologerCall();
  };

  // date filter

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate(null);
      setEndDate(null);
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };

  // const [inputState, setInputState] = useState({
  //   startdate: "",
  // });

  // const [inputStateP, setInputStateP] = useState({
  //   endDate: "",
  // });

  // const handleStartDate = (date) => {
  //   const formattedDate = moment(date).format("MM-DD-YYYY");
  //   setInputState((prevState) => ({ ...prevState, startdate: formattedDate }));
  // };

  // const handleEndDate = (date) => {
  //   const formattedDate = moment(date).format("MM-DD-YYYY");
  //   setInputStateP((prevState) => ({ ...prevState, endDate: formattedDate }));
  // };

  const handleUserNotes = async (id) => {
    const res = await APIServicesSec.get(
      `userNotes/getDetailById/${id}`,
      headers
    );

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        let notes = res.data.data?.notes.reverse();

        setNotesData(res.data.data);
        setShow(true);
      } else {
        console.log("error");
      }
    }
  };

  const handleNotesAdd = async () => {
    if (!note || note === "") {
      setError(true);
    } else {
      let payload = {
        userId: userForNote?.userId[0]?._id,
        source:"Leads-Call-Orders",
        name:userForNote?.callNumber,
        type: "respnder",
        adminName: adminUser?.name,
        adminId: adminUser?._id,
        message: note,
      };
      setLoader(true);
      const res = await APIServicesSec.post(
        `userNotes/userNoteMessage`,
        payload,
        headers
      );

      if (res && res?.data?.status === true) {
        addToast("Note added successfully", {
          appearance: "success",
          autoDismiss: true,
        });

        setShow(false);
        // getUserNotes(userForNote?.userId)
        setNote("");
        setLoader(false);
      } else {
        // toast.error(res?.data?.message);
        addToast(res?.data?.message, {
          appearance: "denger",
          autoDismiss: true,
        });

        setLoader(false);
      }
      setLoader(false);
    }
  };
  const handleChange = () => {
    setError(false);
  };
  const handleCloseModal = () => {
    setShow(false);
    setError(false);
    setNote("");
  };
  // dropdown states

  const [orderStatus, setOrderStatus] = useState("");
  const [callStatus, setCallStatus] = useState("");
  const [callDuration, setCallDuration] = useState("");
  const [source, setSource] = useState("");

  const resetData = () => {
    setStartDate("");
    setEndDate("")
    setSelectedRange("")
    setCallNumber("");
    setUserName("");
    setUserMobNum("");
    setAstroName("");
    setAstroMobNum("");
    setOrderStatus("");
    setCallStatus("");
    setCallDuration("");
    setSource("");
    setFlag(true);
    setPageIndex(0);
  };

  useEffect(() => {
    getAllData(skip, limit, pageIndex);
    handleCountData()
  }, [flag]);

  const handleSearch = () => {
    if (
      callNumber ||
      userName ||
      userMobNum ||
      astroName ||
      astroMobNum ||
      orderStatus ||
      callStatus ||
      callDuration ||
      source ||
      startDate ||
      endDate
    ) {
      getAllData(
        skip,
        limit,
        0,
        callNumber,
        userName,
        userMobNum,
        astroName,
        astroMobNum,
        orderStatus,
        callStatus,
        callDuration,
        source,
        startDate,
        endDate
      );
    }
  };

  const statusMap = {
    4: { text: "Completed", testClass: "text-success fw-bold" },
    1: { text: "Pending", testClass: "text-pending fw-bold" },
    5: { text: "Failed", testClass: "text-danger fw-bold" },
  };

  const columns = useMemo(() => [
    {
      Header: "NO.",
      Cell: (e) => (
        <span>
          {e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}
        </span>
      ),
    },
    {
      Header: "Call Number",
      Cell: (e) => {
        return <span>{e.row.original.callNumber ?? "_"}</span>;
      },
    },
    {
      Header: "Astrologer/Pandit Name	",
      Cell: (e) => {
        return (
          <>
            <span>
              {e.row.original.astrologerId[0]?.name}{" "}
              {e.row.original.astrologerId[0]?.name &&
              e.row.original.astrologerId[0]?.contactNumber
                ? "/"
                : ""}
              {e.row.original.astrologerId[0]?.contactNumber ?? "_"}
            </span><br/>
            <button
              onClick={() => {
                setShowCallCm1(true);
                setAstroId(e.row.original.astrologerId[0]?._id);
              }}
              className="mt-2  call_btn btn btn-secondary w-140"
            >
              Call to Astrologer
            </button>
          </>
        );
      },
    },
    {
      Header: "User Name/Number	",
      Cell: (e) => {
        return (
          <>
            <span>
              {" "}
              {e.row.original.userId[0]?.name}
              {e.row.original.userId[0]?.name &&
              e.row.original.userId[0]?.contactNumber
                ? "/"
                : ""}
              {e.row.original.userId[0]?.contactNumber}
            </span>
            <br />
            <button
              onClick={() => {
                setShowCallCm(true);
                setCurrentUserId(e.row.original.userId[0]?._id);
              }}
              className="mt-2  call_btn btn btn-secondary"
            >
              Call to User
            </button>
          </>
        );
      },
    },
    {
      Header: "Order Amount",
      Cell: (e) => {
        return <span> ₹{e.row.original.orderAmount ?? "_"}.00</span>;
      },
    },
    {
      Header: "IsFree",
      Cell: (e) => {
        return (
          <span>{e.row.original.isFree == false ? "NO" : "Yes" ?? "_"}</span>
        );
      },
    },
    {
      Header: "Duration",
      Cell: (e) => {
        return <span>{e.row.original.duration ?? "_"}min</span>;
      },
    },
    {
      Header: "Price Per Minute	",
      Cell: (e) => {
        return (
          <span>₹{e.row.original.astrologercallPricePerMinute ?? "_"}.00</span>
        );
      },
    },
    {
      Header: "Source",
      Cell: (e) => {
        return <span>{e.row.original.utmSource ?? "_"}</span>;
      },
    },
    {
      Header: "Device",
      Cell: (e) => {
        return <span>{e.row.original.deviceType ?? "_"}</span>;
      },
    },
    {
      Header: "Order Status	",
      Cell: (e) => {
        return (
          <span className={statusMap[e.row.original.orderStatus]?.testClass}>
            {statusMap[e.row.original.orderStatus]?.text}
          </span>
        );
      },
    },
    {
      Header: "Created Date	",
      Cell: (e) => {
        return (
          <span>
            {moment(e.row.original.createdAt).format("DD/MM/YYYY h:mm:ss A")}
          </span>
        );
      },
    },
    {
      Header: "Notes",
      Cell: (e) => {
        return (
          <>
            <h5 className="mb-0">
              <input
                type="button"
                className="Et-btn notes-btn"
                value="Notes"
                onClick={() => {
                  setUserForNote(e.row.original);
                  handleUserNotes(e.row.original.userId[0]?._id);
                }}
              />
            </h5>
                <Button
                    className="detail_btn"
                    onClick={() => {
                      setSingleData(e.row.original);
                      setShowSingleDataDetail(true)
                    }}
                  >
                    View Details
                  </Button>
          </>
        );
      },
    },
  ]);

  useEffect(()=>{
    authorizeResponder()
  },[])
  
  const authorizeResponder =async()=>{
    const res = await APIServiceschatbot.post(
      "responders/authorize_responder",
        {
        },
        headers
      );
      if (res.data?.status == 4) {
        logout();
      }
    }

  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };



  const handleCountData = async (id) => {
    const res = await APIServices.get(`lead/getCallorderCount`, headers);

    if (res && res.status === 200) {
      if (res.data && res.data?.status) {
        setCountData(res.data.resData);
      } else {
        console.log("error");
      }
    }
  };

  const getAllData = useCallback(
    async (skip, limit, index) => {
      try {
        // let page = skip/limit+1
        const response = await APIServices.get(
          `lead/orderList/directCall/${skip}/${limit}/?userName=${userName}&astroName=${astroName}&orderStatus=${orderStatus}&callType=&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}&userMobileNumber=${userMobNum}&callFreePaid=${callStatus}&astrologerId=&duration=${callDuration}&isRewardCoinUsed=&type=&panditName=&countryId=&utmSource=${source}&callNumber=${callNumber}&astrologerMobileNumber=${astroMobNum}`,
          headers
        );
        if (response?.data?.status === true) {
          setPageCount(Math.ceil(response.data?.total_count / limit));
          setAllDataCount(response.data?.total_count);
          setTableData(response.data?.data);
          setFlag(false);
          window.scrollTo({ top: 0 });
        }
        else {
          if (response?.response?.data?.status == 4) {
            logout();
          }
        }
      } catch (e) {
        throw new Error(`API error:${e?.message}`);
      }
      setSkip(skip);
      setLimit(limit);
      setPageIndex(index);
    },
    [
      pageIndex,
      skip,
      orderStatus,
      userName,
      callNumber,
      userMobNum,
      astroName,
      astroMobNum,
      callStatus,
      callDuration,
      source,
      startDate,
      endDate,
    ]
  );

  return (
    <div className="App">
      <div className="gita_quote_heading">
        <div className="container">
          <div className="overflow-x-auto">
            <div className="d-flex counter-wrapper align-items-center justify-content-between">
              <h4 className="hd-list-f font_20 left-q counter-top">
                Total Inquiries <small>{allDataCount}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Free Call <small>{countData?.userfreecall}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
              Today Call <small>{countData?.usersAddedToday}/{countData?.usersAddedPaidToday}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Week Call <small>{countData?.usersAddedThisWeek}/{countData?.usersAddedPaidThisWeek}</small>
              </h4>
              <h4 className="hd-list-f font_20 left-q counter-top">
                This Month Call <small>{countData?.usersAddedThisMonth}/{countData?.usersAddedPaidThisMonth}</small>
              </h4>
              {/* <div class="_btn"></div> */}
            </div>
          </div>
        </div>
      </div>

      {/* <Container className="mb-2 "> */}
      <div class="chat_window">
        <Row className="mlist  my-3 br-6 p-20">
            <div class="chat_search_box user-search">
              <div className="chat-search-field">
                <Row>
                   <Form>
                      <Form.Group as={Row} className="mb-0 mb-lg-3" controlId="">
                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Call Number
                          </Form.Label>

                          <Form.Control
                            className="hd-cr mt-2 form-ch"
                            name="call number"
                            type="text"
                            placeholder="Search Call No"
                            value={callNumber}
                            onChange={(e) => {
                              setCallNumber(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            User Name
                          </Form.Label>

                          <Form.Control
                            className="hd-cr mt-2 form-ch"
                            name="name"
                            type="text"
                            placeholder="Search By Name"
                            value={userName}
                            onChange={(e) => {
                              setUserName(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            User Mobile Number
                          </Form.Label>

                          <Form.Control
                            className="hd-cr mt-2 form-ch"
                            name="number"
                            type="number"
                            placeholder="Search Mobile Number"
                            value={userMobNum}
                            onChange={(e) => {
                              setUserMobNum(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Astrologer Name
                          </Form.Label>

                          <Form.Control
                            className="hd-cr mt-2 form-ch"
                            name="name"
                            type="text"
                            placeholder="Search By Name"
                            value={astroName}
                            onChange={(e) => {
                              setAstroName(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-0 mb-lg-3" controlId="">
                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Mobile Number
                          </Form.Label>

                          <Form.Control
                            className="hd-cr mt-2 form-ch"
                            name="number"
                            type="number"
                            placeholder="Search By Astrologer Mobile Number"
                            value={astroMobNum}
                            onChange={(e) => {
                              setAstroMobNum(e.target.value);
                              setSkip(0);
                            }}
                          />
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            By Order Status
                          </Form.Label>
                          <Form.Select
                            aria-label="label-selected-menu"
                            className="label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                            value={orderStatus}
                            onChange={(e) => {
                              setOrderStatus(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option value=""> Select</option>
                            <option value="1">Pending</option>
                            <option value="4">Completed</option>
                            <option value="5">Failed</option>
                          </Form.Select>
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Call(free/paid/offered)
                          </Form.Label>
                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                            value={callStatus}
                            onChange={(e) => {
                              setCallStatus(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option defaultValue=""> All</option>
                            <option value="free">Free</option>
                            <option value="paid">Paid</option>
                            <option value="isOffer">Offered</option>
                          </Form.Select>
                        </Col>

                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Duration
                          </Form.Label>
                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                            value={callDuration}
                            onChange={(e) => {
                              setCallDuration(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option defaultValue=""> Select</option>
                            <option value="1-1">1 min</option>
                            <option value="2-2">2 min</option>
                            <option value="3-3">3 min</option>
                            <option value="3-5">3-5 min</option>
                            <option value="5-10">5-10 min</option>
                            <option value="10-20">10-20 min</option>
                            <option value="20-100000">20+ min</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="align-items-end mb-1" controlId="">
                      <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Source
                          </Form.Label>
                          <Form.Select
                            aria-label="label-selected-menu"
                            className="fw-bold label-selected-menu text-capitalize  w-50 hd-cr mt-2 form-ch"
                            value={source}
                            onChange={(e) => {
                              setSource(e.target.value);
                              setSkip(0);
                            }}
                          >
                            <option defaultValue=""> All</option>
                            <option value="web">Web</option>
                            <option value="app">App</option>
                          </Form.Select>
                        </Col>
                      <Col md={6}  lg={6} xl={3}  className="mb-3 mb-lg-0"> 
                        <Form.Label class="label-f-w-cd">
                            Select By Call Date
                          </Form.Label> 
                        <div className="date-range-wrapper mt-2">   
                            <div className='select-icon-wrapper'>
                              <select
                              className="select-range"
                                value={selectedRange}
                                onChange={(e) => handleDateRange(e.target.value)}
                              >
                                <option value="">Select Date Range</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="thisWeek">This Week</option>
                                <option value="last7Days">Last 7 Days</option>
                                <option value="thisMonth">This Month</option>
                                <option value="lastMonth">Last Month</option>
                                <option value="customDate">Custom Date</option>
                              </select>
                              <img src='/images/down-arrow.png' className='select-icons'/>
                            </div>

                              {selectedRange === "customDate" && (
                                <DatePicker
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  maxDate={new Date()}
                                  onChange={(update) => {
                                    const [start, end] = update;
                                    setStartDate(start);
                                    setEndDate(end);
                                  }}
                                  // isClearable={true}
                                  placeholderText="Select custom date range"
                                  dateFormat="yyyy-MM-dd"
                                />
                              )}
                            </div>
                          </Col>
  

                        {/* <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd">
                            Start Date
                          </Form.Label>
                          <DatePicker
                            placeholderText={"Select a starting date"}
                            className="form-control form-ch fil-date-pick mt-2"
                            name="startdate"
                            selected={
                              inputState.startdate
                                ? new Date(inputState.startdate)
                                : null
                            }
                            onChange={(date) => {
                              handleStartDate(date);
                              setSkip(0);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Col>
                        <Col lg={3} md={6} className="mb-3 mb-lg-0">
                          <Form.Label class="label-f-w-cd ">
                            End Date
                          </Form.Label>
                          <DatePicker
                            placeholderText={"Select a ending date"}
                            className="form-control form-ch fil-date-pick mt-2"
                            name="startdate"
                            selected={
                              inputStateP.endDate
                                ? new Date(inputStateP.endDate)
                                : null
                            }
                            onChange={(date) => {
                              handleEndDate(date);
                              setSkip(0);
                            }}
                            dateFormat="yyyy-MM-dd"
                          />
                        </Col> */}
                      
                        <Col lg={3} md={6} className="mb-3 mb-lg-0" >
                          <Button
                            variant="secondary"
                            className="as_btn ms-0"
                            onClick={() => {
                              handleSearch();
                              setSkip(0);
                            }}
                          >
                            Filter
                          </Button>
                          <Button
                            variant="secondary"
                            className="as_btn reset-btn"
                            onClick={() => {
                              resetData();
                            }}
                          >
                            Reset
                          </Button>
                        </Col>
                      </Form.Group>
                   </Form>
                </Row>

              </div>
            </div>
        </Row>
                {tableData && tableData.length > 0 ? (
                  <DynamicDataTableWithCount
                    columns={columns}
                    data={tableData}
                    totalCount={pageCount}
                    initialState={{
                      pageIndex: pageIndex,
                      pageSize: limit,
                    }}
                    // notShowPage ={true}
                    fetchData={getAllData}
                    forcePage={skip / limit}
                  />
                ) : (
                  <h2 className="no_records">Records not Available</h2>
                )}
        {/* <BootstrapTable 
            // keyField='index' 
            // data={users}
             columns={columns} /> */}
      </div>
      {/* </Container> */}
      {/* </div> */}
      
                
           

      {/* user call confirmation */}
      <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to make a User call?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {/* for user note */}
      <Modal
        show={show}
        size="md"
        // aria-labelledby='example-modal-sizes-title-sm'
        scrollable={true}
        backdrop="static"
        onHide={handleCloseModal}
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="aa d-flex">
            <span className="me-3">
              {" "}
              Name :- {userForNote?.userId
                ? userForNote?.userId[0]?.name
                : ""}{" "}
            </span>
            <span>
              {" "}
              Contact Number :-{" "}
              {userForNote?.userId
                ? userForNote?.userId[0]?.contactNumber
                : ""}{" "}
            </span>
          </div>

          <Row>
            <Col>
              <Form.Group as={Row} className="my-3" controlId="formAartiInput">
                <Col sm="12">
                  <Form.Control
                    as="textarea"
                    name="content"
                    placeholder="Type here..."
                    value={note}
                    style={{ height: 100 }}
                    onChange={(e) => {
                      handleChange();
                      setNote(e.target.value);
                    }}
                  />
                  {error ? (
                    <p className="text-danger">Please enter your note</p>
                  ) : (
                    ""
                  )}
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Modal.Footer className="py-2 px-0">
            {loader ? (
              <Button variant="primary" className="as_btn">
                Processing...
              </Button>
            ) : (
              <Button
                variant="primary"
                className="as_btn"
                onClick={handleNotesAdd}
              >
                Add
              </Button>
            )}
            <Button
              variant="secondary"
              className="as_btn"
              onClick={handleCloseModal}
            >
              Close
            </Button>
          </Modal.Footer>

          <h6 className="aa mt-2"> Notes History :-</h6>
          <Row>
          
            </Row> 
          
          
          {notesData && notesData?.notes?.length > 0 ? (
            <>
              { notesData?.notes && notesData?.notes?.length>0 &&notesData?.notes?.map((item, index) => (
                <>
                <Row>
                          {item?.source && item?.source != "" ?  
                              <Col xs={5}>
                                  <span className="aa">Source :- </span>
                                    <span className="item-msg">{item.source ? item.source : "" } </span>
                              </Col> : ""
                          }

                          {item?.sourcename && item?.sourcename != "" ? 
                            <Col xs={7}>
                                <span className="aa">Action :- </span>
                                  <span className="item-msg">
                                    {item?.sourcename?.length <= 50 ? item?.sourcename : `${item?.sourcename?.substring(0, 40)}...`}
                                    </span>
                            </Col> : ""
                          }
                </Row>
                
                  <Row className="notes-item">
                    <Col xs={9}>
                      <span className="item-name aa">
                              Note :- 
                      </span>
                      <span className="item-msg"> {item?.message}</span>
                    </Col>
                   
                   
                    <Col xs={3}>
                      <div className="text-end d-flex flex-column">
                          <span className="text-primary item-name aa">
                            {item?.responderName || item?.adminName}
                          </span>
                        <span className="item-text">
                          {item?.createdAt
                            ? moment(item?.createdAt).format("lll")
                            : ""}
                        </span>
                      </div>
                    </Col>
                  </Row>
                </>
              ))}
            </>
          ) : (
            <>
              <h4 className="text-center text-secondary">Not Available</h4>
            </>
          )}
        </Modal.Body>
      </Modal>
      {/* astrologer call confirmation */}

      <Modal
        show={showCallCm1}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm1}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to make a Astrologer call?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e) => handleConfirm1()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleCloseCallCm1}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* show Detail data */}

      <Modal
      className="astrologer_detail-modal"
        show={showSingleDataDetail}
        size="md"
        scrollable={true}
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={()=>setShowSingleDataDetail(false)}
      >
        <Modal.Header className="modal-header-hd" closeButton>
          <Modal.Title> Call Detail : {singleData?.callNumber}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col sm={6} className="mb-3">
                 <h4 className="font_20">Astro/Customer Detail</h4>
                 <p><span className="font-600"> Astrologer Name :</span> {singleData?.astrologerId?.[0]?.name}</p>
                 <p> <span className="font-600">Astrologer Number :</span> {singleData?.astrologerId?.[0]?.contactNumber}</p>
                 <p> <span className="font-600">Customer Name :</span> {singleData?.userId?.[0]?.name} </p>
                 <p> <span className="font-600">Customer Number :</span> {singleData?.userId?.[0]?.contactNumber} </p>
                 <p> <span className="font-600">Source :</span> {singleData?.utmSource 
                        ? singleData.utmSource.charAt(0).toUpperCase() + singleData.utmSource.slice(1)
                        : ''}</p>

                 <p> <span className="font-600">Device :</span> {singleData?.deviceType 
                        ? singleData.deviceType.charAt(0).toUpperCase() + singleData.deviceType.slice(1)
                        : ''}</p>
                 <p> <span className="font-600">Created  :</span> {moment(singleData?.createdAt).format("DD/MM/YYYY h:mm:ss A")}</p>
                 <p> <span className="font-600">Updated  :</span> {moment(singleData?.updatedAt).format("DD/MM/YYYY h:mm:ss A")}</p>
              </Col>
              <Col sm={6} className="mb-0 mb-sm-3">
                 <h4 className="font_20">Call Detail</h4>

                 <p> <span className="font-600">Call Status :</span> {singleData?.orderStatus === 1 ? "Requested" :
                    singleData?.orderStatus === 2 ? "Accepted" : singleData?.orderStatus === 3 ? "In-Progress" :
                    singleData?.orderStatus === 4 ? "Completed" : singleData?.orderStatus === 5 ? "Failed" :
                    singleData?.orderStatus === 6 ? "Cancelled" : "Rejected"}
                 </p>
                 <p> <span className="font-600">Total Amount :</span> {singleData?.currencySymbol}{singleData?.orderAmount}.00</p>
                 <p> <span className="font-600">Price Per Minute :</span> {singleData?.currencySymbol}{singleData?.astrologercallPricePerMinute}.00</p>
                 <p> <span className="font-600">Duration :</span> {singleData?.duration}min</p>
                 <p> <span className="font-600">Astrologer Earning :</span> {singleData?.currencySymbol}
                      {Number.isInteger(singleData?.astrologerEarning) 
                        ? singleData?.astrologerEarning + '.00' 
                        : singleData?.astrologerEarning?.toFixed(2).replace(/\.00$/, '')}
                 </p>
                 <p> <span className="font-600">Commission :</span> {singleData?.astrologerCallCommission}%</p>
                 <p> <span className="font-600">Wallet Used :</span> {singleData?.currencySymbol}{singleData?.usedWalletAmount}.00</p>
                 <p> <span className="font-600">Is Free Call :</span> {singleData?.isFree === true ? "Yes" : "No"} </p>
                 <p> <span className="font-600">Coin Used :</span> {singleData?.isRewardCoinUsed === true ? "Yes" : "No" }</p>
                 <p> <span className="font-600">Coin Amount :</span> {singleData?.currencySymbol}{singleData?.coinAmount}</p>
                 <p> <span className="font-600">Coins :</span> {singleData?.coin}</p>
                 <p> <span className="font-600">Rating Given by Customer :</span> {singleData?.is_rated === true ? "Yes" : "No" }</p>
                 <p> <span className="font-600">Puja Suggested :</span> {singleData?.isPujaSuggested === "yes" ? "Yes" : "No"}</p>
                 <p> <span className="font-600">Astrologer Call Status :</span> {singleData?.astroCallStatus 
                        ? singleData.astroCallStatus.charAt(0).toUpperCase() + singleData.astroCallStatus.slice(1)
                        : ''}</p>
                 <p> <span className="font-600">Payout : </span>{singleData?.payStatus 
                        ? singleData.payStatus.charAt(0).toUpperCase() + singleData.payStatus.slice(1)
                        : ''}</p>
              </Col>
            </Row>
            <hr/>
            <Row>
              <h4 className="font_20">Customer Input</h4>
              <p> <span className="font-600">Birth Place :</span> {singleData?.birthPlace}</p>
              <p> <span className="font-600">Birth Time :</span> {moment(singleData?.birthTime).format("DD/MM/**** h:mm:ss A")}</p>
              <p> <span className="font-600">Name :</span> {singleData?.name}</p>
              <p> <span className="font-600">Gender :</span> {singleData?.gender 
                        ? singleData.gender.charAt(0).toUpperCase() + singleData.gender.slice(1)
                        : ''}</p>
              <p> <span className="font-600">Problem :</span> {singleData?.areaOfProblem} </p>
            </Row>
          </Container>
        </Modal.Body> 
        <Modal.Footer>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={()=>setShowSingleDataDetail(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CallOrders;
