import moment from 'moment';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const setCookies = (name, value, expiryAfterMinutes = 86400) => {
  let expire = new Date();
  expire.setDate(expire.getDate() + expiryAfterMinutes);
  cookies.set(name, value, { expires: expire, path: "/" });
};

export const getCookies = (name) => {
  return cookies.get(name);
};

export const removeCookies = (name, options = { path: '/' }) => {
  cookies.remove(name, options);
};

export function flatten(array) {
  let newArr = [];
  for (let x = 0; x < array.length; x++) {
    if (Array.isArray(array[x])) {
      for (let y = 0; y < array[x].length; y++) {
        newArr.push(array[x][y])
      }
    } else {
      newArr.push(array[x])
    }
  }
  return newArr
}

export const handleDuplicateEvents = (fetchedEvents, events) => {
  let newevents
  if (fetchedEvents.length) {
    const allevnt = fetchedEvents.map(item => item.id)
    newevents = events.filter(event => {
      if (allevnt.includes(event.id)) {
        return false
      } else {
        return true
      }
    });
  } else {
    newevents = events
  }
  return newevents
}


export function getBaseURL(path) {
  if (path) {
    return `${process.env.REACT_APP_IMG_URL}${path}`;
  }
  return null;
}
export const formateDate = (date) => {
  return moment(date).format('YYYY[-]MM[-]DD')

}