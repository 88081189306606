import axios from "axios";


class APIServicenew {
  constructor(url) {
    this.service = axios.create({
      baseURL: url,
      headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
      },
    });
  }

  async get(path, headers = { "content-type": "application/json" }) {
    try {
      const response = await this.service.get(path, {
        headers: headers
      });
      return { status: response.status, data: response.data };
    } catch (error) {
      console.log("ERROR", error);
      return error;
    }
  }

  async post(path, payload, headers = { "content-type": "application/json" }) {
    try {
      const response = await this.service.request({
        method: "POST",
        url: path,
        responseType: "json",
        data: payload,
        headers: headers,
      });
      return response;
    } catch (error) {
      return { status: error.response.status, data: error.response.data };
    }
  }

  async put(path, payload, headers = { "content-type": "application/json" }) {
    try {
      const response = await this.service.request({
        method: "PUT",
        url: path,
        responseType: "json",
        data: payload,
        headers: headers,
      });
      return response;
    } catch (error) {
      return { status: error?.response?.status, data: error?.response?.data };
    }
  }

  async delete(path, headers = { "content-type": "application/json" }, payload= {}) {
    try {
      const response = await this.service.request({
        method: 'DELETE',
        url: path,
        headers: headers,
        data : payload
      });
      return response;
    } catch (error) {
      return error;
    }
  }
}

export default APIServicenew;
