import React, { useEffect, useState, useRef,useMemo, useContext } from "react";
import {
  Row,Modal,
  Col,
  Dropdown,
  Form,
  ToggleButton,
  ButtonGroup,
  Spinner,
  Button,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import APIServicenew from "../utils/APIGeneralService";
import { formateDate, getCookies, setCookies } from "../utils/Helper";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { UserContext } from "../context/UserContextProvider";
import { useToasts } from "react-toast-notifications";
import Papa from "papaparse";
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useCallback } from "react";
import DynamicDataTableWithCount from '../components/dataTableWithCount'
import { Link } from 'react-router-dom';


export default function ResponderCallhistory() {
  const APIServices = new APIServicenew(process.env.REACT_APP_OTHER_API_URL);
  const [users, setUsers] = useState([]);
  const [skip, setSkip] = useState(0);
  const [totalChats, setTotalChats] = useState("");
  const token = getCookies("accessTokenleads");
  const navigate = useNavigate();
  const [responderaa, setResponderaa] = useState([]);
  const { refreshToken } = useContext(UserContext);
  const { user: adminUser } = useContext(UserContext);
  // const [responderId, setResponderId] = useState(adminUser?._id);
  const { addToast } = useToasts();
  const [pageIndex, setPageIndex] = useState(0);
  const [offset, setOffset] = useState(0);
  const [perPage, setPerPage] = useState(40);
  const [pageCount, setPageCount] = useState(0);
  const [flag, setFlag] = useState(false);
  // for user calling modal
  const [dial, setDail] = useState("Call");
  const [showCallCm, setShowCallCm] = useState(false);
  const [currentUserId, setCurrentUserId] = useState("");
 const [callType, setCallType] = useState("")
 const [callHistoryStatus,setCallHistoryStatus] = useState(false)
const [callHistoryData,setcallHistoryData] = useState([])

 const handleCallType =(e)=>{
  setCallType(e.target.value)
}
  const handleCloseCallCm = () => {
    setShowCallCm(false);
  };

  const userCall = async () => {
    const res = await APIServices.post(
      "responders/call",
      {
        userId: currentUserId,
        source: "callhistory",
      },
      headers
    );

    if (res && res.status === 200) {
      setDail(res?.data?.message);
      setShowCallCm(false);
      addToast("Calling....", {
        appearance: "success",
        autoDismiss: true,
      });
      setTimeout(() => {
        setDail("Call");
      }, 10000);
    }
  };
  const handleConfirm = () => {
    userCall();
  };


  // for astrologer calling modal

  const [dial1,setDail1] = useState("Call")
  const [showCallCm1 ,setShowCallCm1] = useState(false)
  const [astroId, setAstroId] = useState("")

  const handleCloseCallCm1 = () =>{setShowCallCm1(false)}

  const astrologerCall =async() =>{
    const res = await APIServices.post("responders/callAstrologer",{
      astrologerId : astroId,
        source: "callhistory"
      },headers);
      if (res && res.status === 200) {
        setDail1(res?.data?.message)
        setShowCallCm1(false)
        addToast("Astrologer Calling....", {
          appearance: 'success',
          autoDismiss: true
        })
        setTimeout(()=>{
          setDail1('Call')
        },10000)
      }

  }

  const handleConfirm1 = () =>{
    astrologerCall()
  }

  const headers = {
    Authorization: `Bearer ${token}`,
  };

 // new 
  
 const [callStatus, setCallStatus] = useState('');
 const handleCallStatus = (event) => {
   setCallStatus(event.target.value);
 };

 const [source , setSource] = useState('')
 const handleSourceStatus=(e)=>{
   setSource(e.target.value)
 }

 const [callTo,setCallTo] = useState('') 
 const handleCallTo=(e)=>{
   setCallTo(e.target.value)
 }



  // const [inputState, setInputState] = useState({
  //   startdate: "",
  //   enddate: "",
  // });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedRange, setSelectedRange] = useState("");
  
   const handleDateRange = (range) => {
    const now = new Date();
    let start, end;

    if (range === "disabled") {
      setStartDate("");
      setEndDate("");
      setSelectedRange("");
      return;
    }

    switch (range) {
      case "today":
        start = end = now;
        break;
      case "yesterday":
        start = end = new Date(now.setDate(now.getDate() - 1));
        break;
      case "thisWeek":
        start = new Date(now.setDate(now.getDate() - now.getDay()));
        end = new Date(now.setDate(now.getDate() + 6 - now.getDay()));
        break;
      case "last7Days":
        start = new Date(now.setDate(now.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(now.getFullYear(), now.getMonth(), 1);
        end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
        break;
      case "lastMonth":
        start = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        end = new Date(now.getFullYear(), now.getMonth(), 0);
        break;
      case "customDate":
        start = end = null;
        break;
      default:
        break;
    }

    setStartDate(start);
    setEndDate(end);
    setSelectedRange(range);
  };


  const logout = () => {
    refreshToken(null);
    setCookies("accessTokenleads", null);
    navigate("/");
  };
 
  // const handleInputChange = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, startdate: formattedDate }));
  // };
  // const handleInputChangeEnd = date => {
  //   const formattedDate = moment(date).format('YYYY-MM-DD');
  //   setInputState(prevState => ({ ...prevState, enddate: formattedDate }));
  // };




  const statusMap = {
    2: { text: "Completed", testClass: "text-success fw-bold" },
    0: { text: "Pending", testClass: "text-pending fw-bold" },
    1: { text: "Initiated", testClass: "text-pending fw-bold" },
    3: { text: "Failed", testClass: "text-danger fw-bold" },
  };

  const columns = useMemo(()=> [
    
    {
      Header: 'NO.',
      Cell: e => <span>{e.state.pageIndex * e.state.pageSize + 1 + Number(e.row.id)}</span>,
    },
    {
      Header: "Customer Name/Number",
      // text: "Customer Name/Number",
      Cell: e => {

        return (
          <>
            <span>
              {e.row.original.userName} {e.row.original.userName && e.row.original.userNumber ? "/" : ""}{" "}
              {e.row.original.userNumber}
            </span>
            <br />
            {e.row.original.userName == "" ? (
              ""
            ) : (
              <button className="mt-2 call_btn btn btn-secondary" onClick={()=> {
                setShowCallCm(true); setCurrentUserId(e.row.original.userId)}}>
                Call to User
              </button>
            )}
          </>
        );
      },
    },
    {
      Header: "Astrologer Name/Number",
      // text: "Astrologer Name/Number",
      Cell: e => {
        return (
          <>
            <span>
              {" "}
              {e.row.original.astrologerName}{" "}
              {e.row.original.astrologerName && e.row.original.astrologerNumber ? "/" : ""}{" "}
              {e.row.original.astrologerNumber}
            </span>
            <br />
            {e.row.original.astrologerName == "" ? (
              ""
            ) : (
              <button className="mt-2  call_btn btn btn-secondary"
              onClick={()=> {
                setShowCallCm1(true); setAstroId(e.row.original.astrologerId)}}
              >
                Call to Astrologer
              </button>
            )}
          </>
        );
      },
    },
    {
      Header: "Call Status",
      // text: "Call Status",
      Cell: (e) => {
        return (
          // <span className={statusMap[e.row.original.callStatus]?.testClass}>
          //   {statusMap[e.row.original.callStatus]?.text}
          // </span>
          <>
           <span className={statusMap[e.row.original.callStatus]?.testClass}>
                   {statusMap[e.row.original.callStatus]?.text}
                </span>
                <br/>
                { e.row.original?.callStatus == "3" && e.row.original?.callHistory && e.row.original.callHistory.length > 0 ? 
                  <Link className="" style={{fontSize:"12px"}}
                    onClick={()=>{ setcallHistoryData(e.row.original); 
                    setCallHistoryStatus((e.row.original?.callStatus == "3" && e.row.original?.callHistory ) ? true : false) }} 
                  >View History
                  </Link>: ""
                }
          </>
        );
      },
    },
    
    {
      Header: "Source",
      // text: "Source",
      Cell: e => {
        return <span>{e.row.original.source ?? '_'}</span>;
      },
    },
    {
      Header: "Call to",
      // text: "Call to",
      Cell: e => {
        return <span>{e.row.original.callTo ?? '_'}</span>;
      },
    },
    {
      Header: "Call Type",
      Cell: e => {
        return <span>{e.row.original.callType ?? '_'}</span>;
      },
    },
    {
      Header: "Duration",
      // text: "Duration",
      Cell: e => {
        return <span>{(e.row.original.duration)+ " " + "min" ?? '_'}</span>;
      },
    },
    {
      Header: "Date",
      // text: "Date",
      Cell: e => {
        return (
          <>
            <span>{e.row.original.createdAt ? moment(e.row.original.createdAt).format('lll') : '_'}</span>
          </>
        );
      },
    },
    {
      Header: "Modified Date",
      // text: "Modified Date",
      Cell: e => {
        return (
          <>
            <span>{e.row.original.updatedAt ? moment(e.row.original.updatedAt).format('lll') : '_'}</span>
          </>
        );
      },
    },
  ]);
  
  useEffect(() => {
      fetchedAllUsers(offset, perPage, pageIndex, adminUser?._id);
  }, [adminUser?._id,flag]);

  useEffect(()=>{
    authorizeResponder()
  },[])
  
  const authorizeResponder =async()=>{
    const res = await APIServices.post(
      "responders/authorize_responder",
        {
        },
        headers
      );
      if (res.data?.status == 4) {
        logout();
      }
    }


  const handleFilterClick = () => {
    if ( source || callTo || callType || callStatus || startDate || endDate || perPage || offset || pageIndex ) {
      fetchedAllUsers(offset, perPage, 0, adminUser?._id, startDate, endDate, callTo, callStatus, source, callType  );
    }
  };

  const fetchedAllUsers = useCallback (async (offset, perPage, index, responderId)=>{
    const res = await APIServices.get(
      `/responders/callListing/${offset}/${perPage}?userId=${currentUserId}&responderId=${responderId || ''}&callStatus=${callStatus}&source=${source}&callTo=${callTo}&callType=${callType}&startDate=${startDate ? formateDate(startDate) : ""}&endDate=${endDate ? formateDate(endDate) : ""}`,
      headers
    );
    if (res && res.status === 200) {
      let resData = res.data;
      if (resData && resData.status) {
        let newData = [...resData?.data];
        setFlag(false)
        setUsers(newData);
        setTotalChats(resData.total_count);
        setPageCount(Math.ceil(resData.total_count / perPage));
      }
    }
    else{
      if (res?.response?.status === 401) {
        logout();
      }
    }
    setOffset(offset);
    setPerPage(perPage);
    setPageIndex(index);
  },
  [ pageIndex,offset,adminUser?._id ,callStatus,source, callTo,callType,startDate,endDate, ]
  ) 

  const handleReset =()=>{
    setPageIndex(0);
    setOffset(0);
    setFlag(true);
   setStartDate("")
   setEndDate("")
   setSelectedRange("")
    setCallType("")
    setCallStatus('')
    setSource('')
    setCallTo('')
  }

  return (
    <>
      <div className="gita_quote_heading">
        <div className="container d-flex align-items-center justify-content-between">
          <h4 className="hd-list-f font_20 left-q counter-top">
            {" "}
            My Call History <small>{totalChats}</small>{" "}
          </h4>
        </div>
      </div>
      <div className="chat_window">
        <Row className="mlist br-6 p-20 my-3">
          <div class="chat_search_box">
            <Row className="align-items-end">
              <Col md={6} lg={3} className="mb-3">
                <Form.Label>Call Status</Form.Label>
                <Form.Select
                  aria-label="label-selected-menu"
                  className="fw-bold label-selected-menu text-capitalize  w-50"
                  onChange={handleCallStatus}
                  value={callStatus}
                >
                  <option className="fw-bold" value="">
                    All
                  </option>
                  <option className="text-capitalize" value="0">
                    Pending
                  </option>
                  <option className="text-capitalize" value="1">
                    Initiated
                  </option>
                  <option className="text-capitalize" value="2">
                    Completed
                  </option>
                  <option className="text-capitalize" value="3">
                    Failed
                  </option>
                </Form.Select>
              </Col>
              <Col md={6} lg={3} className="mb-3">
                <Form.Label>Source</Form.Label>
                <Form.Select
                  aria-label="label-selected-menu"
                  className="fw-bold label-selected-menu text-capitalize  w-50"
                  onChange={handleSourceStatus}
                  value={source}
                >
                  <option className="fw-bold" value="all">
                    All
                  </option>
                  <option className="text-capitalize" value="puja">
                    puja
                  </option>
                  <option className="text-capitalize" value="lead">
                    lead
                  </option>
                  <option className="text-capitalize" value="call">
                    call
                  </option>
                  <option className="text-capitalize" value="chat">
                    chat
                  </option>
                  <option className="text-capitalize" value="customers">
                    Customers
                  </option>
                  <option className="text-capitalize" value="callhistory">
                    callhistory
                  </option>
                  <option
                    className="text-capitalize"
                    value="puja recommendation	"
                  >
                    Puja Recommendation
                  </option>
                </Form.Select>
              </Col>
              <Col md={6} lg={3} className="mb-3">
                <Form.Label>Call to (Astrologer/User)</Form.Label>
                <Form.Select
                  aria-label="label-selected-menu"
                  className="fw-bold label-selected-menu text-capitalize  w-50"
                  onChange={handleCallTo}
                  value={callTo}
                >
                  <option className="fw-bold" value="all">
                    All
                  </option>
                  <option className="text-capitalize" value="astrologer">
                    Astrologer
                  </option>
                  <option className="text-capitalize" value="user">
                    User
                  </option>
                </Form.Select>
              </Col>
              <Col md={6} lg={3} className="mb-3">
                <Form.Label>Call Type</Form.Label>
                <Form.Select
                  aria-label='label-selected-menu'
                  className='fw-bold label-selected-menu text-capitalize  w-50'
                  onChange={handleCallType}
                  value={callType}
                >
                  <option className='fw-bold' value='all'>
                    All
                  </option>
                  <option className='text-capitalize' value='incoming'>
                    Incoming
                  </option>
                  <option className='text-capitalize' value='outgoing'>
                     Outgoing
                  </option>
                </Form.Select>
              </Col>
              <Col md={6}  lg={3}>
              <Form.Label>Select By History Date</Form.Label>
                <div className="date-range-wrapper">
                    <div className='select-icon-wrapper'> 
                        <select
                        className="select-range"
                          value={selectedRange}
                          onChange={(e) => handleDateRange(e.target.value)}
                        >
                          <option value="">Select Date Range</option>
                          <option value="today">Today</option>
                          <option value="yesterday">Yesterday</option>
                          <option value="thisWeek">This Week</option>
                          <option value="last7Days">Last 7 Days</option>
                          <option value="thisMonth">This Month</option>
                          <option value="lastMonth">Last Month</option>
                          <option value="customDate">Custom Date</option>
                        </select>
                        <img src='/images/down-arrow.png' className='select-icons'/>
                    </div>

                      {selectedRange === "customDate" && (
                        <DatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          maxDate={new Date()}
                          onChange={(update) => {
                            const [start, end] = update;
                            setStartDate(start);
                            setEndDate(end);
                          }}
                          // isClearable={true}
                          placeholderText="Select custom date range"
                          dateFormat="yyyy-MM-dd"
                        />
                      )}
                  </div>
              </Col>
              {/* <Col md={6} lg={3}  className="mb-3 mb-md-0">
                <Form.Label>Start Date</Form.Label>
                
                 <DatePicker
                  placeholderText={'Select by Starting Date'}
                  className='form-control form-ch fil-date-pick '
                  name='pujaDate'
                  selected={inputState.startdate ? new Date(inputState.startdate) : null}
                  onChange={date => {
                    handleInputChange(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />

              </Col>
              <Col md={6} lg={3}  className="mb-3 mb-md-0">
                <Form.Label>End Date</Form.Label>
                
                 <DatePicker
                  placeholderText={'Select by Ending Date'}
                  className='form-control form-ch fil-date-pick '
                  name='pujaDate'
                  selected={inputState.enddate ? new Date(inputState.enddate) : null}
                  onChange={date => {
                    handleInputChangeEnd(date);
                    setSkip(0);
                  }}
                  dateFormat='yyyy-MM-dd'
                />
              </Col> */}
              <Col md={4} lg={3} xl={2} className='mt-3 mt-lg-4  mb-md-0'>
                <Button className='as_btn ms-0' variant='primary' onClick={handleFilterClick}>
                  Filter
                </Button>
                <Button
                  className="as_btn reset-btn"
                  variant="primary"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </Col>
              {/* <Col md={2} className="mt-4 mb-1">
              </Col> */}
            </Row>
          </div>
          {/* <BootstrapTable keyField="index" data={users} columns={columns} /> */}
          {/* {totalChats > 50 && (
            <div className="d-flex justify-content-center align-items-left w-100 p-0 mt-2 custom-pagination">
              <ReactPaginate
                previousLabel="Previous"
                nextLabel="Next"
                breakLabel="..."
                forcePage={skip / 50}
                pageCount={totalChats < 50 ? 1 : Math.ceil(totalChats / 50)} // Total number of pages
                marginPagesDisplayed={2} // Number of pages to display at the start and end of the pagination
                pageRangeDisplayed={1} // Number of pages to display in the middle of the pagination
                // onPageChange={handlePageChange} // Callback function when page is changed
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          )} */}
        </Row>
              <DynamicDataTableWithCount
                columns={columns}
                data={users}
                totalCount={pageCount}
                initialState={{
                  pageIndex: pageIndex,
                  pageSize: perPage,
                }}
                fetchData={fetchedAllUsers}
                forcePage={offset / perPage}
              />
         {/* user call confirmation */}
      <Modal
        show={showCallCm}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure you want to make a User call?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e)=>handleConfirm()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleCloseCallCm}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* astrologer call confirmation */}

     <Modal
        show={showCallCm1}
        size="sm"
        aria-labelledby="example-modal-sizes-title-sm"
        backdrop="static"
        onHide={handleCloseCallCm1}
      >
        <Modal.Header className="modal-header-hd">
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure you want to make a Astrologer call?
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="as_btn"
            onClick={(e)=>handleConfirm1()}
          >
            Yes
          </Button>
          <Button
            variant="secondary"
            className="as_btn"
            onClick={handleCloseCallCm1}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

       {/* call Status */}

       <Modal
          show={callHistoryStatus}
          size='sm'
          aria-labelledby='example-modal-sizes-title-sm'
          backdrop='static'
          onHide={()=>{setCallHistoryStatus(false)}}
        >
          <Modal.Header className='modal-header-hd'>
            <Modal.Title>No Answer</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2 border-bottom">
              <span className="fw-semibold">Customer Number :-    </span>
              <span>{callHistoryData?.userNumber}</span>
            </div>
            <div>
              {
                callHistoryData?.callHistory && callHistoryData?.callHistory?.length>0 && 
                callHistoryData?.callHistory.map((item,index)=>{
                  return(<>
                    <div key={item?._id}>
                      <span className="fw-semibold">Responder Number {index+1} :-    </span>
                      <span>{item?.responderNumber}</span>
                    </div>
                  </>)
                })
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant='secondary' className='cancle_btn'
             onClick={()=>{setCallHistoryStatus(false)}}
             >
              Cancel
            </Button>
           
          </Modal.Footer>
        </Modal>
        
      </div>
    </>
  );
}
